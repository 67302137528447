import React, { useEffect, useState } from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  List,
  ListItem,
  TextField,
  FormHelperText
} from '@material-ui/core'
import * as Yup from 'yup'
// import { Formik,Form } from "formik";
// import TextField from "@material-ui/core/TextField";
import { Link, useHistory, useLocation } from 'react-router-dom'
import Scroll from 'react-scroll'
import axios from 'axios'
import * as yup from 'yup'
import apiConfig from 'src/config/apiConfig'
import { toast } from 'react-toastify'
// import { Formik } from "formik";
import { Formik, ErrorMessage, useFormik, Form } from 'formik'
import ButtonCircularProgress from '../Loaders/ButtonCircularProgress'
import { Alert } from '@material-ui/lab'
const ScrollLink = Scroll.Link

const useStyles = makeStyles(theme => ({
  footerSection: {
    background: '#152233d6',
    position: 'relative',
    padding: '60px 0px 30px',
    '& .contentSection': {
      '& p': {
        color: '#aeaeae',
        lineHeight: '28px'
      },
      '& h5': {
        // color: "#aeaeae",
        // fontSize: "16px",
        width: '310px',
        height: '70px',
        fontSize: '20px',
        color: '#aeaeae',
        fontWeight: 400,
        fontFamily: 'Roboto'
      },
      '& h3': {
        color: '#aeaeae',
        fontSize: '24px',
        fontWeight: '800',
        lineHeight: '29px'
      },
      '& .imgbox': {
        paddingBottom: '15px'
      },
      '& .linktext': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
          color: '#aeaeae',
          '&:hover': {
            color: '#1f5fad'
          }
        },
        [theme.breakpoints.down('xs')]: {
          justifyContent: 'start',
          alignItems: 'start'
        }
      }
    }
  },
  copyright: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      color: '#f2f2f2',
      fontSize: '15px',
      fontWeight: 400,
      fontFamily: 'Roboto',
      textAlign: 'center'
    },
    '& span': {
      fontSize: '15px',
      // color: "#f2f2f2",
      fontWeight: 400,
      fontFamily: 'Roboto',
      textAlign: 'center',
      color: '#2885f5'
    }
  },
  button: {
    backgroundColor: '#1f5fad',
    color: '#fff',
    padding: '10px 50px',
    marginTop: '15px',
    width: '100%',
    maxWidth: '260px',
    height: '41px',
    borderRadius: '20px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%'
    }
  },
  inputfield: {
    border: '1px solid #FFFFFF',
    borderRadius: '25px',
    backgroundColor: '#FFFFFF'
  },
  textFild: {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // width: "98%",
    // height: "41px",
    // // paddingLeft:"30px",
    // borderRadius: "100px",
    // //border:"2px solid #fff",
    // maxWidth: "280px",
    // // backgroundColor: "#ffffff",
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%'
    },
    '& input': {
      paddingLeft: '10px',
      border: 'none',
      outline: 'none',
      borderRadius: '20px',
      backgroundColor: 'white',
      fontSize: '14px',
      lineHeight: '29px',
      color: '#676767',
      fontWeight: '400',
      fontFamily: 'Roboto',
      width: '94%',
      // maxWidth: "300px",
      height: '41px',
      borderRadius: '20px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: '100%'
      }
    }
  }
}))
const initialValues = {
  email: ''
}
const validationSchema = yup.object({
  email: yup.string().email().required('Email is required')
})
const isValidEmail = value => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
  return re.test(String(value).toLowerCase())
}

export default function Liquidity() {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [isUpdating, setisUpdating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [btnText, setBtnText] = useState('SUBMIT')
  const [message, setMessage] = useState()

  const formInitialSchema = {
    email: ''
  }
  const formValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('You have entered an invalid email address. Please try again')
      .required('Email address is required')
      .matches('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$')
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, resetForm) => {
      // console.log(values);
      try {
        setisUpdating(true)
        const res = await axios.post(apiConfig.addNewsLetter, {
          email: values.email
        })
        if (res.data.statusCode === 200) {
          setisUpdating(false)
          toast.success('Your newsletter subscribed successfully')
          resetForm({ values: '' })
        }
      } catch (error) {
        setisUpdating(false)
        console.log('ERROR', error)
        if (error.response) {
          toast.error(error.response.data.responseMessage)
        } else {
          toast.error(error.responseMessage)
        }
      }
    }
  })

  const handleFormSubmit = async (values, resetForm) => {
    setIsLoading(true)

    try {
      // setisUpdating(true);
      const res = await axios.post(apiConfig.addNewsLetter, {
        email: values.email
      })
      if (res.data.statusCode === 200) {
        setisUpdating(false)
        toast.success('Your newsletter subscribed successfully')
        resetForm({ values: '' })
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        setMessage('Try again later...')
      } else {
        setMessage('Try again later...')
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth='lg'>
          <Grid container spacing={4}>
            <Grid item sm={4} xs={12} md={4} lg={3}>
              <Box className='contentSection'>
                <Box className='imgbox'>
                  <Link to='/app/landingpage'>
                    <img src='/images/logoDummy.svg' alt='footer logo' />
                  </Link>
                </Box>
                <Typography variant='h6' style={{color:'#aeaeae'}}>
                  Next generation DEFI platform with an Ecosystem launcher
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={6} sm={4}>
              <Box className='contentSection'>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Box className='linktext'>
                      <Box>
                        <Link
                          to='/app/About'
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography variant='body2'>About Project</Typography>
                        </Link>
                        <Link
                          to='/app/privacy'
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography variant='body2'>
                            Privacy Policy
                          </Typography>
                        </Link>
                        <Link
                          to='/app/terms'
                          style={{ textDecoration: 'none', color: '#aeaeae' }}
                        >
                          <Typography variant='body2'>
                            Terms and Condition
                          </Typography>
                        </Link>
                        {history.location.pathname !== '/app/landingpage' ? (
                          <List>
                            <ListItem>
                              <Link
                                to='/'
                                style={{
                                  textDecoration: 'none',
                                  color: '#aeaeae'
                                }}
                              >
                                <Typography variant='body2'>
                                  Token Allocation
                                </Typography>{' '}
                              </Link>
                            </ListItem>
                          </List>
                        ) : (
                          <List>
                            <ListItem>
                              <ScrollLink
                                smooth={true}
                                duration={500}
                                style={{
                                  textDecoration: 'none ',
                                  cursor: 'pointer',
                                  color: '#aeaeae'
                                }}
                                to='section1'
                              >
                                <Typography variant='body2'>
                                  Token Allocation{' '}
                                </Typography>
                              </ScrollLink>
                            </ListItem>
                          </List>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className='linktext'>
                      <Box>
                        {history.location.pathname !== '/app/landingpage' ? (
                          <List>
                            <ListItem>
                              <Link
                                to='/'
                                style={{
                                  textDecoration: 'none',
                                  color: '#aeaeae'
                                }}
                              >
                                <Typography variant='body2'>
                                  Our Solutions
                                </Typography>{' '}
                              </Link>
                            </ListItem>
                          </List>
                        ) : (
                          <List>
                            <ListItem>
                              <ScrollLink
                                smooth={true}
                                duration={500}
                                style={{
                                  textDecoration: 'none ',
                                  cursor: 'pointer',
                                  color: '#aeaeae'
                                }}
                                to='section0'
                              >
                                <Typography variant='body2'>
                                  Our Solutions{' '}
                                </Typography>
                              </ScrollLink>
                            </ListItem>
                          </List>
                        )}
                        <Typography variant='body2'>
                          {history.location.pathname !== '/app/landingpage' ? (
                            <List>
                              <ListItem>
                                <Link
                                  to='/'
                                  style={{
                                    textDecoration: 'none',
                                    color: '#aeaeae'
                                  }}
                                >
                                  <Typography variant='body2'>
                                    Roadmap
                                  </Typography>{' '}
                                </Link>
                              </ListItem>
                            </List>
                          ) : (
                            <List>
                              <ListItem>
                                <ScrollLink
                                  smooth={true}
                                  duration={500}
                                  style={{
                                    textDecoration: 'none ',
                                    cursor: 'pointer'
                                  }}
                                  to='section2'
                                >
                                  Road Map{' '}
                                </ScrollLink>
                              </ListItem>
                            </List>
                          )}
                        </Typography>
                        {/* <Typography variant="body2">White Paper</Typography> */}
                        <List>
                          <ListItem>
                            <Link
                              to='/'
                              style={{
                                textDecoration: 'none',
                                color: '#aeaeae'
                              }}
                            >
                              <Typography variant='body2'>
                                Whitepaper
                              </Typography>{' '}
                            </Link>
                          </ListItem>
                        </List>
                        {/* <Typography variant="body2">Docs</Typography> */}
                        <List>
                          <ListItem>
                            <Link
                              to='/'
                              style={{
                                textDecoration: 'none',
                                color: '#aeaeae'
                              }}
                            >
                              <Typography variant='body2'>Docs</Typography>{' '}
                            </Link>
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item sm={4} xs={12} md={4} lg={3}>
              <Box className='contentSection' textAlign='center'>
                <Typography variant='h3'>Newsletter</Typography>
                <Typography variant='body2'>Keep in touch with us!</Typography>

                <Box mt={2} className={classes.textFild}>
                  <Formik>
                    <Box
                      // className="col-md-12 textcenter mt-5"
                      style={{ width: '100%' }}
                    >
                      <form
                        spacing={2}
                        onSubmit={(values, resetForm) =>
                          formik.handleSubmit(values, resetForm)
                        }
                      >
                        <input
                          placeholder='Enter Your Email Address'
                          name='email'
                          fullWidth
                          value={formik.values.email}
                          {...formik.getFieldProps('email')}
                          style={{ color: '#000', cursor: 'start' }}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div
                            style={{
                              textAlign: 'start',
                              padding: '0 0 0 20px'
                            }}
                            className='error'
                          >
                            {formik.errors.email}
                          </div>
                        ) : null}

                        <Button
                          className={classes.button}
                          disabled={isUpdating}
                          // variant="contained"
                          //  color="primary"
                          type='submit'
                          variant='contained'
                          // component="span" //It is actually hidden
                          color='primary'
                        // size="large"
                        >
                          SUBSCRIBE
                          {isUpdating && <ButtonCircularProgress />}
                        </Button>
                      </form>
                    </Box>
                  </Formik>
                  {/* <Formik
                    initialValues={formInitialSchema}
                    initialStatus={{
                      success: false,
                      successMsg: "",
                    }}
                    validationSchema={formValidationSchema}
                    // onSubmit={(values) => handleFormSubmit(values)}
                    onSubmit={(values, { resetForm }) =>
                      handleFormSubmit(values, resetForm)
                    }
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,

                      touched,
                      values,
                    }) => (
                      <Form>
                        <Box className={classes.boxmainParent}>
                          <Box className={classes.formBox}>
                            <Box className={classes.feildItem}>
                              <TextField
                                label="Enter Your Email"
                                type="text"
                                // variant="outlined"
                                fullWidth
                                size="small"
                                name="email"
                                value={values.email}
                                error={Boolean(touched.email && errors.email)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={isLoading}
                                style={{ color: "#000", cursor: "start" }}
                              />
                              <FormHelperText error>
                                {touched.email && errors.email}
                              </FormHelperText>
                            </Box>
                            <Box
                              style={{
                                marginTop: "1rem",
                                display: "flex",
                              }}
                            >
                              <Button
                                className={classes.buttonbox}
                                variant="contained"
                                color="secondary"
                                type="submit"
                                disabled={isLoading}
                              >
                                Submit
                                {isLoading && <ButtonCircularProgress />}
                              </Button>

                            </Box>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik> */}
                  {/* <Formik
                    initialValues={{
                      email: "",
                      submit: null,
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email("You have entered an invalid email address.")
                        .required("Email address is required")
                        .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/),
                    })}
                    onSubmit={async (
                      values,
                      { resetForm, setErrors, setStatus, setSubmitting }
                    ) => {
                      console.log("Submited", values);
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box
                          className="col-md-12 textcenter mt-5"
                          style={{ width: "100%" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <Box>
                                <input
                                  placeholder="Enter Your Email Address"
                                  name="email"
                                  fullWidth
                                  // {...formik.getFieldProps("email")}
                                  style={{ color: "#000", cursor: "start" }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                          <Box mt={4}>
                            <Button
                              color="secondary"
                              disabled={isSubmitting}
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              SUBSCRIBE
                            </Button>
                          </Box>
                        </Box>
                      </form>
                    )}
                  </Formik> */}
                </Box>
                {/* <Button
                  className={classes.button}
                  // onClick={submitHandler}
                  // disabled={isUpdating}
                  variant="contained"
                  color="primary"
                  type="submit"
                  // onClick={submitSubscribeHandler}
                  // onClick={() =>
                  //   formik.setTouched({
                  //     email: "true",
                  //   })
                  // }
                >
                  SUBSCRIBE
                  {isUpdating && <ButtonCircularProgress />}
                </Button> */}
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.copyright} pt={5}>
            <Typography variant='body1'>
              Copyright 2024 <span>@ LaunchZone</span>
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  )
}
