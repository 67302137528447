import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import classnames from "classnames";
import { makeStyles, Box } from "@material-ui/core";
//icons

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages

import { useLayoutState } from "../../context/LayoutContext";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    // padding: "24px 100px 0",
    // paddingTop:"24px",
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    minHeight: "100vh",
    paddingTop: "100px",
    zIndex: "1",
    overflow: "hidden",
    position: "relative",
  },
  content1: {
    flexGrow: 1,
    padding: "0",
    width: `100%`,
    minHeight: "100vh",
    paddingTop: "86px",
    zIndex: "1",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      paddingTop: "75px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "55px",
    },
  },
  contentShift: {
    width: `calc(100vw - 274px)`,
    position: "absolute",
    right: "0",
    [theme.breakpoints.down("sm")]: {
      width: `100%`,
    },
  },
  contentShift2: {
    width: `100%`,
    position: "absolute",
    right: "0",
    backgroundColor:'#09121d',
    [theme.breakpoints.down("sm")]: {
      width: `100%`,
    },
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    "&:not(:first-child)": {
      paddingLeft: 15,
    },
  },

  buttonright: {
    background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
    fontSize: "14px ",
    border: "1px solid transparent",
    fontWeight: 600,
    height: "44px ",
    color: "#FFFFFF",
    minWidth: "135px ",
    borderRadius: "5px",
    boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      color: "#fff",
      transition: "0.5s",
      boxSizing: " border-box",
      border: " 2px solid transparent",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
    },
  },
  mainBodyBox: {
    minHeight: "calc(100vh - 260px)",
  },
}));
function Layout(props) {
  const classes = useStyles();
  const [layoutCheck, setLayoutCheck] = useState(false);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  useEffect(() => {
    const urlCheck = window.location.href.split("#");
    // console.log("urlCheck----", urlCheck);
    if (urlCheck[1] === "/app/landingpage") {
      setLayoutCheck(true);
    } else {
      setLayoutCheck(false);
    }
  }, [window.location.href]);

  var layoutState = useLayoutState();
  return (
    <div className={classes.root}>
      {layoutCheck ? (
        <>
          <Header history={props.history} />
          <div
            className={classnames(classes.content1, {
              [classes.contentShift2]: layoutState.isSidebarOpened,
            })}
          >
            <Box className={classes.mainBodyBox}>
              <Suspense fallback={() => null}>
                <Switch>
                  <Route
                    path="/app/landingpage"
                    component={lazy(() =>
                      import("src/pages/LandingPage/Index")
                    )}
                  />
                </Switch>
              </Suspense>
            </Box>
            <Footer />
          </div>
        </>
      ) : (
        <>
          <Header history={props.history} />
          <Sidebar />

          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <Box className={classes.mainBodyBox}>
              <Suspense fallback={() => null}>
                <Switch>
                  Staking
                  {/* <Route
                  path='/app/home'
                  component={lazy(() => import("../../pages/Home/Home"))}
                /> */}
                  <Route
                    path="/app/dashboard"
                    component={lazy(() => import("../../pages/dashboard"))}
                  />
                  <Route
                    path="/app/Pool"
                    component={lazy(() => import("../../pages/Pool/Pool"))}
                  />
                  <Route
                    path="/app/About"
                    component={lazy(() => import("../../pages/About/About"))}
                  />{" "}
                  <Route
                    path="/app/multiSender"
                    component={lazy(() =>
                      import(
                        "../../pages/ShieldexMultiSender/ShieldexMultiSender"
                      )
                    )}
                  />{" "}
                  <Route
                    path="/app/features"
                    component={lazy(() =>
                      import("../../pages/Feature/Features")
                    )}
                  />{" "}
                  <Route
                    path="/app/contact"
                    component={lazy(() =>
                      import("../../pages/Contact/Contact")
                    )}
                  />{" "}
                  <Route
                    path="/app/token"
                    component={lazy(() => import("../../pages/token/Token"))}
                  />{" "}
                  <Route
                    path="/app/view-token"
                    component={lazy(() =>
                      import("../../pages/token/Tab/ViewToken")
                    )}
                  />{" "}
                  {/* <Route
                    path="/app/launched"
                    component={lazy(() =>
                      import("../../pages/launched/Launched")
                    )}
                  /> */}
                  <Route
                    path="/app/sniffer"
                    component={lazy(() =>
                      import("../../pages/sniffer/Sniffer")
                    )}
                  />{" "}
                  <Route
                    path="/app/contract-sniffer"
                    component={lazy(() =>
                      import("../../pages/ContractSniffer/Index")
                    )}
                  />{" "}
                  <Route
                    path="/app/kyc"
                    component={lazy(() => import("../../pages/KYC/KycList"))}
                  />{" "}
                  <Route
                    path="/app/add-kyc"
                    component={lazy(() => import("../../pages/KYC/AddKyc"))}
                  />{" "}
                  <Route
                    path="/app/view-kyc"
                    component={lazy(() => import("../../pages/KYC/ViewKyc"))}
                  />{" "}
                  {/* <Route
                    path="/app/notification"
                    component={lazy(() =>
                      import("../../pages/Notification/Notification")
                    )}
                  /> */}
                  <Route
                    path="/app/create-launched"
                    component={lazy(() =>
                      import("../../pages/launched/Launchedform")
                    )}
                  />
                  <Route
                    path="/app/public-create-launched"
                    component={lazy(() =>
                      import("../../pages/launchedPublic/Launchedform")
                    )}
                  />
                  <Route
                    path="/app/tokenform"
                    component={lazy(() =>
                      import("../../pages/token/TokenCreateform")
                    )}
                  />{" "}
                  <Route
                    path="/app/formdata"
                    component={lazy(() =>
                      import("../../pages/launched/Createform")
                    )}
                  />
                  <Route
                    path="/app/create-stake"
                    component={lazy(() =>
                      import("../../pages/staking/CreateStake")
                    )}
                  />{" "}
                  {/* {/ <Route path="/app/reward-staking" component={Rewards} /> /} */}
                  <Route
                    path="/app/withDraw-stake"
                    component={lazy(() =>
                      import("../../pages/staking/Withdraw")
                    )}
                  />
                  {/* <Route
                    path="/app/launched-detail"
                    component={lazy(() =>
                      import("../../pages/launched/LaunchedDetail")
                    )}
                  />{" "} */}
                  <Route
                    path="/app/Promot-list"
                    component={lazy(() =>
                      import("../../pages/Admin/PromotList")
                    )}
                  />{" "}
                  <Route
                    path="/app/varify-list"
                    component={lazy(() =>
                      import("../../pages/Admin/VarifiedList")
                    )}
                  />{" "}
                  <Route
                    path="/app/pre-sale"
                    component={lazy(() => import("../../pages/Admin/Index"))}
                  />{" "}
                  <Route
                    path="/app/admin-control"
                    component={lazy(() =>
                      import("../../pages/Admin/AdminControl")
                    )}
                  />{" "}
                  <Route
                    path="/app/view-members"
                    component={lazy(() =>
                      import("../../pages/Admin/ViewMembers")
                    )}
                  />{" "}
                  <Route
                    path="/app/launched-live-presale"
                    component={lazy(() =>
                      import("../../pages/launched/LivePreSales")
                    )}
                  />
                  <Route
                    path="/app/public-live-presale"
                    component={lazy(() =>
                      import("../../pages/launchedPublic/LivePreSales")
                    )}
                  />
                  {/* <Route
                    path="/app/liquidity"
                    component={lazy(() =>
                      import("../../pages/liquidity/Liquidity")
                    )}
                  />{" "} */}
                  <Route
                    path="/app/lock-liquidity"
                    component={lazy(() =>
                      import("../../pages/LiquidityLocker/Locker")
                    )}
                  />
                  <Route
                    path="/app/past-presale"
                    component={lazy(() =>
                      import("../../pages/PastPreSale/Index")
                    )}
                  />{" "}
                  <Route
                    path="/app/public-past-presale"
                    component={lazy(() =>
                      import("../../pages/PublicPastPreSale/Index")
                    )}
                  />{" "}
                  <Route
                    path="/app/view-sniffer"
                    component={lazy(() =>
                      import("../../pages/ContractSniffer/ViewSniffer")
                    )}
                  />{" "}
                  <Route
                    path="/app/view-sniffer-details"
                    component={lazy(() =>
                      import("../../pages/ContractSniffer/ViewSnifferDetails")
                    )}
                  />{" "}
                  <Route
                    path="/app/upcoming-presale"
                    component={lazy(() =>
                      // import("../../pages/Upcoming/Index")
                      import("../../pages/Upcoming/Index")
                    )}
                  />{" "}
                  <Route
                    path="/app/public-upcoming-presale"
                    component={lazy(() =>
                      // import("../../pages/Upcoming/Index")
                      import("../../pages/PublicUpcoming/Index")
                    )}
                  />{" "}
                  <Route
                    path="/app/token-details"
                    component={lazy(() =>
                      // import("../../pages/Upcoming/Index")
                      import("../../pages/LiquidityLocker/Tab/TokenDetails")
                    )}
                  />{" "}
                  <Route
                    path="/app/lock-token-details"
                    component={lazy(() =>
                      // import("../../pages/Upcoming/Index")
                      import(
                        "../../pages/LiquidityLocker/TabToken/TokenDetails"
                      )
                    )}
                  />{" "}
                  <Route
                    path="/app/verifyed-presale"
                    component={lazy(() =>
                      import("../../pages/VerifiedPresell/Index")
                    )}
                  />{" "}
                  <Route
                    path="/app/public-verifyed-presale"
                    component={lazy(() =>
                      import("../../pages/PublicVerifiedPresell/Index")
                    )}
                  />{" "}
                  {/* <Route
                  path='/app/locker'
                  component={lazy(() =>
                    import("../../pages/LiquidityLocker/Locker")
                  )}
                /> */}
                  <Route
                    path="/app/lock-token"
                    component={lazy(() =>
                      import("../../pages/LiquidityLocker/TokenLocker")
                    )}
                  />
                  {/* <Route
                    path="/app/staking"
                    component={lazy(() =>
                      import("../../pages/staking/Staking")
                    )}
                  />{" "} */}
                  <Route
                    path="/app/live-staking"
                    component={lazy(() =>
                      import("../../pages/staking/LiveStake")
                    )}
                  />{" "}
                  <Route
                    path="/app/finished-staking"
                    component={lazy(() =>
                      import("../../pages/staking/FinishedStake")
                    )}
                  />{" "}
                  <Route
                    path="/app/terms"
                    component={lazy(() =>
                      import("src/components/StasticData/Terms")
                    )}
                  />{" "}
                  <Route
                    path="/app/privacy"
                    component={lazy(() =>
                      import("src/components/StasticData/Privacy")
                    )}
                  />{" "}
                  {/* <Route
                    path="/app/token"
                    component={lazy(() =>
                      import("src/components/pages/LandingPage/Token")
                    )}
                    
                  />{" "}
                  <Route
                    path="/app/roadmap"
                    component={lazy(() =>
                      import("src/components/pages/LandingPage/Roadmap")
                    )}
                    
                  />{" "}
                  <Route
                    path="/app/solution"
                    component={lazy(() =>
                      import("src/components/pages/LandingPage/Solution")
                    )}
                    
                  />{" "} */}
                  <Route
                    path="/app/documentation"
                    component={lazy(() =>
                      import("src/components/StasticData/Documentation")
                    )}
                  />{" "}
                  <Route
                    path="/app/audits"
                    component={lazy(() =>
                      import("src/components/StasticData/Audits")
                    )}
                  />{" "}
                  <Route
                    path="/app/membership"
                    component={lazy(() =>
                      import("../../pages/Membership/Membership")
                    )}
                  />{" "}
                  <Route
                    path="/app/public-membership"
                    component={lazy(() =>
                      import("../../pages/PublicMembership/Membership")
                    )}
                  />{" "}
                  <Route
                    path="/app/staking-details"
                    component={lazy(() =>
                      import("../../pages/ViewStakingDetails/Index")
                    )}
                  />
                  <Route
                    path="/app/sheild-stake"
                    component={lazy(() =>
                      import("../../pages/StakingDetails/Index")
                    )}
                  />{" "}
                  <Route
                    exact
                    path="/app/ui"
                    render={() => <Redirect to="/app/ui/icons" />}
                  />
                  <Route
                    path="/app/view-locker"
                    component={lazy(() =>
                      import("../../pages/LiquidityLocker/ViewLocker")
                    )}
                  />
                </Switch>
              </Suspense>
            </Box>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
}

export default withRouter(Layout);
