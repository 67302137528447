import Web3 from "web3";
export const NetworkContextName = "bsc Test Network";
export const ACTIVE_NETWORK = 97;
export const deadAddress = "0x0000000000000000000000000000000000000000";
export const deadAddressDead = "0x000000000000000000000000000000000000dEaD";
/*****************Admin---------Wallet---------Address***********/
export const AdminOwner = "0xbBEBFCd82cdd310707fc23C804543e487eAE3b9a";
export const serviceFee = "0.00001";
export const pancakeRouter = "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3";
export const DividentTokenAddress =
  "0xdBF6b8a3D6f09c3A06D6385B79a66d14e7382e77";
export const textDeadAddress =
  "0x0000000000000000000000000000000000000000000000000000000000000000";
export const factoryContractAdress =
  "0x57abb8c0221c55C463e1a7436a06D7E179DB96fC";
export const liquidityLockerAddress =
  "0xB34325B5e5b178e93b38098Eb89257A21F610E62";
export const multiSenderAddress = "0x728ebcec1436a6C7b17854C4B80DAFcc6860ED56";

export const BUSDTokenAddress = "0x21783C0Ce32e1859F6bccC6e575Ae6019765e443";
export const USDTTokenAddress = "0x54f6719302106a7462cbea1318b515b2d3ec8456";
export const ShieldexStakingContracAddress =
  "0xd99003c4beeb275969F4012715f72220A126B5C9";
export const maxPromote = 10;

export const default_RPC_URL = "https://bsc-prebsc-dataseed.bnbchain.org/";
// export const default_RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545";
// export const default_RPC_URL =
//   "https://bsc-testnet.infura.io/v3/c7dfdf62595b4093a3d8b78bfad151c3";

export const explorerURL = "https://testnet.bscscan.com";
export const tokenCreatorFees = "10000000000000";
export const ammFactory = "0xB7926C0430Afb07AA7DEfDE6DA862aE0Bde767bc";
export const wbnbAddress = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";
export const NetworkDetails = [
  {
    chainId: "0x61",
    chainName: "Smart Chain Network",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [default_RPC_URL],
    blockExplorerUrls: [explorerURL],
  },
];

export const getWeb3Obj = async (RPC_URL = default_RPC_URL) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = async (abi, contractAddress, RPC_URL) => {
  const web3 = await getWeb3Obj(RPC_URL);
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};
export const getPromoteFees = (days) => {
  switch (days.toString()) {
    case "1":
      return 0.001;
    case "2":
      return 0.002;
    case "3":
      return 0.003;
  }
};

export const poolData = [
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "Rise Of Defenders - GA",
    text2: "RDR / BUSD",
    maxBusd: "49.99968",
    access: "BSL",
    progress: "100.00%",
    participants: "205",
    text3: "277778/277778",
    ratio: "27.77778 RDR",
  },
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "Rise Of Defenders - BSL",
    text2: "RDR / BUSD",
    maxBusd: "2749.968",
    access: "BSL",
    progress: "100.00%",
    participants: "356",
    text3: "2500000/2500000",
    ratio: "27.77778 RDR",
  },
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "SnapEx — BSL",
    text2: "RDR / BUSD",
    maxBusd: "5225",
    access: "BSL",
    progress: "100.00%",
    participants: "480",
    text3: "80000000/80000000",
    ratio: "40 SNAP",
  },
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "Rise Of Defenders - GA",
    text2: "RDR / BUSD",
    maxBusd: "49.99968",
    access: "BSL",
    progress: "100.00%",
    participants: "205",
    text3: "277778/277778",
    ratio: "27.77778 RDR",
  },
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "Rise Of Defenders - BSL",
    text2: "RDR / BUSD",
    maxBusd: "2749.968",
    access: "BSL",
    progress: "100.00%",
    participants: "356",
    text3: "2500000/2500000",
    ratio: "27.77778 RDR",
  },
  {
    img1: "images/Pool1.png",
    img2: "images/Pool2.png",
    text1: "SnapEx — BSL",
    text2: "RDR / BUSD",
    maxBusd: "5225",
    access: "BSL",
    progress: "100.00%",
    participants: "480",
    text3: "80000000/80000000",
    ratio: "40 SNAP",
  },
];
